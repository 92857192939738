
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

@Component({
  inheritAttrs: false
})
export default class InputUnit extends Vue {
  @Prop({ default: "单位" })
  unitName: string;
  @Prop({ default: "" })
  unitType: string;
}
